.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-weight: 900;
}
.inputBox {
  margin: 40px 0px;
}
.root {
  position: relative;
  margin-top: 5px;
}

.input {
  height: 35px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 2px;
  border-radius: 0;
  padding-left: 0;
  background-color: var(--mantine-color-body);
  &:focus-within {
    outline: transparent;
    animation: border-flash 5s infinite;
  }

  input {
    padding-left: 0 !important;
    &:focus-within {
      outline: transparent;
      border-color: transparent;
    }
  }
}

.input2 {
  height: 35px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 2px;
  border-radius: 0;
  padding-left: 0;
  background-color: var(--mantine-color-body);
  outline: transparent;
  animation: border-flash 5s infinite;
  &:focus-within {
    outline: transparent;
    padding-left: 5px !important;
  }
  input {
    padding-left: 5px !important;
    outline: transparent;
    border-color: transparent;
    &:focus-within {
      outline: transparent;
      border-color: transparent;
    }
  }
}
.label {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 5px;
  color: var(--mantine-color-placeholder);
  font-weight: 400;
  font-size: 15px;
  transition: color 100ms ease, transform 100ms ease, font-size 100ms ease;

  &[data-floating] {
    transform: translateY(-30px);
    font-size: 15px;
    color: var(--mantine-color-text);
  }
}

.button {
  margin-top: 6%;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 0 20px;
  width: 100%;
  height: 50px;

  background: repeating-linear-gradient(
    to right,
    rgb(0, 247, 255),
    rgb(0, 132, 255),
    rgb(183, 2, 255),
    rgb(0, 132, 255),
    rgb(0, 247, 255)
  );

  &:hover {
    background-size: 200% auto;
    background-position: 0 100%;
    animation: 4s linear infinite gradient;
  }
}

@keyframes border-flash {
  0% {
    border-color: rgb(0, 247, 255);
    border-width: 4px;
  }
  25% {
    border-color: rgb(0, 132, 255);
    border-width: 4px;
  }
  50% {
    border-color: rgb(183, 2, 255);
    border-width: 4px;
  }
  75% {
    border-color: rgb(0, 132, 255);
    border-width: 4px;
  }
  100% {
    border-color: rgb(0, 247, 255);
    border-width: 4px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: -100% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
