.tab {
    position: relative;
    border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  
    &:first-of-type {
      border-radius: rem(4px) 0 0 rem(4px);
  
      @mixin rtl {
        border-radius: 0 rem(4px) rem(4px) 0;
      }
    }
  
    &:last-of-type {
      border-radius: 0 rem(4px) rem(4px) 0;
  
      @mixin rtl {
        border-radius: rem(4px) 0 0 rem(4px);
      }
    }
  
    & + & {
      border-left-width: 0;
  
      @mixin rtl {
        border-right-width: 0;
        border-left-width: rem(1px);
      }
    }
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
    }
  
    &[data-active] {
      z-index: 1;
      background-color: var(--mantine-color-blue-filled);
      border-color: var(--mantine-color-blue-filled);
      color: var(--mantine-color-white);
  
      @mixin hover {
        background-color: var(--mantine-color-blue-filled-hover);
      }
    }
  }