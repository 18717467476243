.addreply{
    color: rgb(95, 95, 235);
    cursor: pointer;
}
.btext{
    color: black;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.text{
    font-size: 14px;
}
.ctitle{
    white-space: nowrap; 
    font-size: 16px;
    font-weight: 700;
    color: rgb(95, 95, 235);
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis; 
    padding-bottom: 10px;
}
.ctitle>span{
    text-decoration-line: underline;
    cursor: pointer;
}
.content{
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-bottom: 10px;
}
.newreplybox{
    display: flex;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 10px;
}
.newreplybox>p{
    padding-right: 10px;
    color: black;
}
.newreplybox>button{
    margin-left: 10px;
}
.newreplybox>div>div>textarea{
    height: 80px;
}
.newreply{
    width: 100%;
    
}
.btext2{
    color: rgb(255, 0, 0);
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.box{
    width: 100%;
    padding-bottom: 10px;
}