.treeTable {
  width: 800px;
  border: 1px solid black;
  td,
  th {
    padding: 5px 20px 5px 5px;
  }
  .header {
    font-weight: bold;
    border: 1px solid black;
    td {
      padding: 5px;
      text-align: center;
      align-items: center;
    }
  }
  .name {
    font-weight: 600;
  }
  .expanded {
    color: blue;
    font-weight: 600;
  }
  .viewPage,
  .visibleType,
  .idCate {
    position: absolute;
  }
  .viewPage {
    left: 39%;
  }
  .visibleType {
    left: 70%;
  }
  .idCate {
    left: 94%;
  }
}
